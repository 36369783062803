import type { Language } from '@magicschool/supabase/types';
import type { MessageFormatElement } from 'react-intl';

const loadLocaleData = async (locale: Language) => {
  switch (locale) {
    case 'en-us':
      return await import('@/features/locales/data/en-us.json');
    case 'en-gb':
      return await import('@/features/locales/data/en-gb.json');
    case 'es':
      return await import('@/features/locales/data/es.json');
    case 'fr':
      return await import('@/features/locales/data/fr.json');
    case 'fr-ca':
      return await import('@/features/locales/data/fr.json');
    case 'he':
      return await import('@/features/locales/data/he.json');
    case 'hi':
      return await import('@/features/locales/data/hi.json');
    case 'zh-cn':
      return await import('@/features/locales/data/zh-cn.json');
    case 'zh-hk':
      return await import('@/features/locales/data/zh-hk.json');
    case 'ja':
      return await import('@/features/locales/data/ja.json');
    case 'de':
      return await import('@/features/locales/data/de.json');
    case 'ar':
      return await import('@/features/locales/data/ar.json');
    case 'it':
      return await import('@/features/locales/data/it.json');
    case 'id':
      return await import('@/features/locales/data/id.json');
    default:
      return await import('@/features/locales/data/en-us.json');
  }
};

export type LocaleData = Record<string, string> | Record<string, MessageFormatElement[]>;

export const getLocaleData = (locale: Language) => loadLocaleData(locale).then((data) => data.default as LocaleData);
